import React from "react";
import { useRouter } from "next/router";
import { Button, Image, classNames } from "@gonoodle/gn-universe-ui";
import get from "lodash/get";

import ChampChatBubble from "./ChampChatBubble";
import ProgressBar from "./ProgressBar";
import { useUser } from "../../contexts/user";
import { useChampions, useCurrentChampion, useProfile } from "../../hooks";
import { useTransmogrifier } from "../../contexts/Transmogrifier";
import { useLogEvent } from "../../contexts/Analytics";
import { getUtmParamsFromQueryParams } from "../../utils/analytics";
import { pauseAllJWPlayers } from "../../utils/jwplayer";
import { VIDEO_STATES } from "../../constants";

export default function ChampProgressBar({ videoState }) {
  const { user } = useUser();
  const router = useRouter();
  const champion = useCurrentChampion();
  const { champions } = useChampions();
  const { profile } = useProfile();
  const {
    points,
    earnedPoints,
    canGraduate,
    canLevelUp,
    canEarnPoints,
    canRegister,
    transitionTypes,
    transition,
    setTransition,
    levelUpChamp,
    earnPoints,
    redirectToRegistration,
  } = useTransmogrifier();

  const isLoggedOutUser = !user.isLoggedIn;
  const videoIsPaused = [VIDEO_STATES.COMPLETED, VIDEO_STATES.PAUSED].includes(
    videoState,
  );

  const phaseTransitionImage = React.useMemo(() => {
    if (!champion || !champions) return null;

    const currentChampionWithImages = champions.find(
      (champ) => champ.id === champion.champId,
    );

    if (isLoggedOutUser) {
      return get(currentChampionWithImages, "images.phase2.full", null);
    }

    return get(
      currentChampionWithImages,
      ["images", `phase${champion.level + 1}`, "full"],
      null,
    );
  }, [champion, champions, isLoggedOutUser]);

  useLogEvent({
    event: "Unregistered Champ Achievement",
    properties: {
      ...getUtmParamsFromQueryParams(router.query),
    },
    options: {
      referrer: () => {},
      enabled:
        champion && transition === transitionTypes.pointAdded && canRegister,
    },
  });

  useLogEvent({
    event: "Reach Champ Achievement",
    properties: {
      champName: champion?.name,
      level: String(champion?.level),
    },
    options: {
      referrer: () => {},
      enabled:
        champion && transition === transitionTypes.pointAdded && canLevelUp,
    },
  });

  const { logEvent: logLevelUpVideoSkippedEvent } = useLogEvent({
    event: "Level Up Video Skipped",
    options: {
      referrer: () => {},
    },
  });

  const { logEvent: logLevelUpVideoEvent } = useLogEvent({
    event: "Level Up Video",
    options: {
      referrer: () => {},
    },
  });

  const handleAddPoints = () => {
    pauseAllJWPlayers();
    setTransition(transitionTypes.pointAdded);
    earnPoints();
  };

  const levelUpChampion = React.useCallback(
    (skip) => {
      if (skip === true) {
        levelUpChamp();
        logLevelUpVideoSkippedEvent({
          newChampLevel: String(champion.level + 1),
        });
      } else {
        pauseAllJWPlayers();
        setTransition(transitionTypes.levelUp);
        logLevelUpVideoEvent({ newChampLevel: String(champion.level + 1) });
      }
    },
    [
      levelUpChamp,
      logLevelUpVideoSkippedEvent,
      champion?.level,
      setTransition,
      transitionTypes.levelUp,
      logLevelUpVideoEvent,
    ],
  );

  const graduateChampion = React.useCallback(() => {
    pauseAllJWPlayers();
    setTransition(transitionTypes.graduate);
  }, [setTransition, transitionTypes.graduate]);

  if (!champion) {
    return null;
  }

  return (
    <section className="sticky bottom-0 order-last z-20 max-sm:mt-5 mt-16">
      {!transition && videoState && canEarnPoints && videoIsPaused && (
        <ChampChatBubble
          message={`You've earned ${earnedPoints} point${
            earnedPoints > 1 ? "s" : ""
          }! Watch your Champ add points to the progress bar now.`}
        >
          <Button
            className="mt-4 w-full"
            variant="vivid"
            size="md"
            onPress={handleAddPoints}
          >
            Add Point{earnedPoints > 1 ? "s" : ""}
          </Button>
        </ChampChatBubble>
      )}

      {!transition && canLevelUp && (
        <ChampChatBubble
          message={`Nice job! You got all ${champion.pointsPerLevel} points!`}
          imageSources={
            phaseTransitionImage
              ? {
                  "regular@1x": phaseTransitionImage["1x"],
                  "regular@2x": phaseTransitionImage["2x"],
                  "regular@3x": phaseTransitionImage["3x"],
                }
              : null
          }
        >
          <Button
            className="mt-4 w-full"
            variant="vivid"
            size="md"
            onPress={levelUpChampion}
          >
            Level Up!
          </Button>

          <Button
            className="mt-4 w-full"
            variant="dark-outline"
            size="md"
            onPress={() => {
              levelUpChampion(true);
            }}
          >
            Skip
          </Button>
        </ChampChatBubble>
      )}

      {!transition && canGraduate && (
        <ChampChatBubble
          message="Congratulations! You've maxed out your Champ"
          imageSources={
            phaseTransitionImage
              ? {
                  "regular@1x": phaseTransitionImage["1x"],
                  "regular@2x": phaseTransitionImage["2x"],
                  "regular@3x": phaseTransitionImage["3x"],
                }
              : null
          }
        >
          <Button
            className="mt-4 w-full"
            variant="vivid"
            size="md"
            onPress={graduateChampion}
          >
            Graduate Champ
          </Button>
        </ChampChatBubble>
      )}

      {!transition && canRegister && (
        <ChampChatBubble
          message="Want to continue the fun? Create your free account now to earn points, grow your Champ, and save your favorite videos!"
          imageSources={
            phaseTransitionImage
              ? {
                  "regular@1x": phaseTransitionImage["1x"],
                  "regular@2x": phaseTransitionImage["2x"],
                  "regular@3x": phaseTransitionImage["3x"],
                }
              : null
          }
        >
          <Button
            className="mt-4 w-full"
            variant="vivid"
            size="md"
            onPress={redirectToRegistration}
          >
            Create a Free Account
          </Button>
        </ChampChatBubble>
      )}

      <div
        className={classNames(
          "bg-black flex items-stretch",
          "max-sm:flex-col max-sm:items-start",
        )}
      >
        <div className="flex items-center gap-2 shrink-0 pt-4 px-5 py-2 sm:py-2 sm:px-5 relative">
          <div className="relative">
            <Image
              className="h-20 max-sm:h-14"
              sources={{
                "regular@1x": champion.images.headshot["1x"],
                "regular@2x": champion.images.headshot["2x"],
                "regular@3x": champion.images.headshot["3x"],
              }}
              alt=""
            />
          </div>
          <div className="flex flex-col shrink-0">
            {user.isLoggedIn && (
              <p className="text-sm font-bold text-purple-400">
                Level {`${champion.level}/${5}`}
              </p>
            )}
            <p className="text-md font-bold text-white">{champion.name}</p>
            <p className="text-xs text-gray-200 max-w-2xl truncate max-sm:max-w-3xl">
              {isLoggedOutUser ? "Level 1" : profile.name}
            </p>
          </div>
        </div>

        <div className="w-full py-4 px-5 sm:py-2 sm:px-5 flex items-center">
          <ProgressBar
            current={points}
            max={champion.pointsPerLevel}
            limit={champion.pointsPerLevel}
          />
        </div>
      </div>
    </section>
  );
}
